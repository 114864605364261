<template>
	<!-- <div v-if="isRecapcha">
		<h2
			class="auth-form__captcha-title"
			style="margin-bottom: 8px;"
		>
			Подтвердите, что вы не робот
		</h2>
		<button @click="test()">test</button>
		<vue-recaptcha
			:sitekey="vueAppCaptchaKey"
			@verify="onRecatchaSuccess"
		/>
	</div> -->
	
	<ValidationObserver
		class="auth-step__form"
		tag="form"
		ref="firstStepForm"
		v-slot="{ invalid }"
		@submit.prevent="setCaptcha"
	>
		<h2 class="auth-form__title">Войти или создать профиль</h2>
		<UiValidatedInput
			rules="required|phone"
			v-model="phone"
			id="auth-phone"
			label="Телефон"
			type="tel"
			name="phone"
			:mask="mask"
		/>
		<UiButton
			:disabled="invalid"
			:loading="loading"
		>
			Получить код
		</UiButton>
		<UiButton
			@click.prevent="setAuthStep(4)"
			variant="text"
			size="small"
		>
			Вход без смс
			<ArrowIcon class="step-arrow__icon first-step__icon"/>
		</UiButton>
		<UserAgreementLinks style="text-align: center"/>
	</ValidationObserver>
</template>
<script>
import {UiButton, UiValidatedInput, UserAgreementLinks} from "ui-kit";
import {mapActions, mapMutations} from "vuex";
import {notification} from "@/utils";
import ArrowIcon from "icons/24/arrow-back.svg?inline";
import VueRecaptcha from 'vue-recaptcha';

export default {
	name: "FirstStep",
	components: {
		UiValidatedInput,
		UiButton,
		UserAgreementLinks,
		ArrowIcon,
		VueRecaptcha,
	},
	data()
	{
		return {
			mask: {
				mask: '+T (###) ###-##-##',
				tokens: {
					'T': {
						pattern: /[0-9]/, transform: (char) =>
						{
							switch (char.toString())
							{
								case '9':
									return '7 (9';
								case '3':
									return '7 (3';
								default:
									return '7';
							}
						}
					}
				},
			},
			phone: '',
			loading: false,
			isRecapcha: false,
			vueAppCaptchaKey: process.env.VUE_APP_CAPTCHA_KEY
		}
	},
	methods: {
		...mapActions({
			sendSmsCode: "sendSmsCode",
		}),
		...mapMutations({
			setAuthStep: "setAuthStep",
		}),
		setCaptcha() {
      window.grecaptcha.ready(() => window.grecaptcha.execute("6Ld6SPcqAAAAAJxKn9Slgar2r1DfBbSnU9Lbr5hj", { action: "submit" }).then(token => setTimeout(this.handleSubmitForm(token, true), 250)));
    },
		async handleSubmitForm(verifyCode, fromCaptcha)
		{
			const isValide = await this.$refs.firstStepForm?.validate()

			if (!isValide && !fromCaptcha) return

			this.loading = true;

			try
			{
				const promocode = this.$cookie.get("promo") || '';
				const params = {
					phone: this.phone,
					promocode,
					...(verifyCode && { verifyCode }),
				};

				const { data } = await this.sendSmsCode(params);

				if (!data.success)
				{
					if (data.errors.captcha)
						return notification({ title: 'Попробуйте позже или повторите попытку', type: "error" });
					
					const title = data.msg || data.errors.phone[0] || "Неизвестная ошибка";
					return notification({title, type: "error"});
				}

				this.$emit("fillPhoneNumber", this.phone);
				this.setAuthStep(2);
			} catch (e)
			{
				throw new Error(e);
			} finally
			{
				this.loading = false;
			}
		}
	}
}
</script>
<style lang="scss">
.first-step__icon
{transform: rotate(180deg);}
</style>